/**
* @license
*
* Font Family: Clash Display
* Designed by: Indian Type Foundry
* URL: https://www.fontshare.com/fonts/clash-display
* © 2024 Indian Type Foundry
*
* Clash Display Extralight 
* Clash Display Light 
* Clash Display Regular 
* Clash Display Medium 
* Clash Display Semibold 
* Clash Display Bold 
* Clash Display Variable (Variable font)

*
*/
@font-face {
  font-family: "ClashDisplay-Extralight";
  src: url("../assets/fonts/ClashDisplay-Extralight.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Extralight.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Light";
  src: url("../assets/fonts/ClashDisplay-Light.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Light.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Regular";
  src: url("../assets/fonts/ClashDisplay-Regular.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Regular.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Medium";
  src: url("../assets/fonts/ClashDisplay-Medium.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Medium.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Semibold";
  src: url("../assets/fonts/ClashDisplay-Semibold.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Semibold.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Bold";
  src: url("../assets/fonts/ClashDisplay-Bold.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Bold.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: "ClashDisplay-Variable";
  src: url("../assets/fonts/ClashDisplay-Variable.woff2") format("woff2"),
    url("../assets/fonts/ClashDisplay-Variable.woff") format("woff"),
    url("../assets/fonts/ClashDisplay-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

h1,
p {
  text-align: center;
}

a {
  display: block;
  text-align: center;
  margin-top: 20px;
}
@font-face {
  font-family: "ClashDisplay";
  src: url("/src/assets/fonts/ClashDisplay-Regular.woff2") format("woff2"),
    url("/src/assets/fonts/ClashDisplay-Regular.woff") format("woff"),
    url("/src/assets/fonts/ClashDisplay-Regular.ttf") format("truetype"),
    url("/src/assets/fonts/ClashDisplay-Regular.eot")
      format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ClashDisplay";
  src: url("/src/assets/fonts/ClashDisplay-Bold.woff2") format("woff2"),
    url("/src/assets/fonts/ClashDisplay-Bold.woff") format("woff"),
    url("/src/assets/fonts/ClashDisplay-Bold.ttf") format("truetype"),
    url("/src/assets/fonts/ClashDisplay-Bold.eot") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
}

/* Repeat this for other font weights if needed */

/* CTA Section Styling */
.cta-section {
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.cta-btn {
  padding: 12px 30px;
  font-size: 1.2rem;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s;
  color: white;
}

.primary-cta {
  background-color: white;
  color: #db4a2b;
  border: 2px solid white;
}

.secondary-cta {
  background-color: transparent;
  border: 2px solid white;
}

.primary-cta:hover {
  background-color: #f4f4f4;
  color: #db4a2b;
}

.secondary-cta:hover {
  background-color: white;
  color: #db4a2b;
}

/* Hero Section Styling */
.hero {
  position: relative;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #e9eef3; Dark background */
  text-align: left;
  padding-left: 80px;
  padding-right: 50px;
  padding-bottom: 20px;
  padding-top: 80px;
  overflow: hidden;
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.hero-content {
  z-index: 2; /* Keep content above other elements */
  max-width: 600px;
}

.hero-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: #ffffff; /* Yellow for text to match the style */
  margin-bottom: 20px;
  line-height: 1.2;
  font-family: "ClashDisplay-Bold"; /*Similar font to the example*/
  text-align: left;
  line-height: 100%;
  /* font-family: "Bebas Neue", sans-serif; Similar font to the example */
}

.hero-subtitle {
  font-size: 1.25rem;
  /* font-family: "ClashDisplay-Medium"; */
  color: #fef1f1;
  margin-bottom: 40px;
  text-align: left;
  line-height: 100%;
}

.hero-buttons {
  display: flex;
  gap: 20px;
}

.cta-btn-1 {
  display: inline-block;
  padding: 10px 30px;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s;
  cursor: pointer;
}

.primary-cta-1 {
  background-color: #00c9a7;
  color: white;
  border: 2px solid #00c9a7;
}

.secondary-cta-1 {
  background-color: white;
  color: #00c9a7;
  border: 2px solid #00c9a7;
}

.primary-cta-1:hover {
  color: white;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}
.secondary-cta-1:hover {
  color: #00c9a7;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.hero-image {
  max-width: 500px;
  z-index: 1;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-lottie {
  max-width: 600px;
  height: 600px;
}
